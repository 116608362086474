<template>
    <div class="page">
        <el-row class="query-form p_b1">
            <el-col :span="20">
                <el-input size="small" v-model="searchForm.journalismTitle" placeholder="新闻标题" maxlength="50"
                          class="m_r1"
                          style="width: 15%" clearable></el-input>
                <el-select size="small" v-model="searchForm.newsCategoryId" placeholder="请选择分类" class="m_r1"
                           style="width: 15%" clearable>
                    <el-option
                            v-for="item in newsCategoryOpt"
                            :key="item.id"
                            :label="item.newsCategoryName"
                            :value="item.id">
                    </el-option>
                </el-select>
                <el-date-picker class="m_r1" size="small"
                                value-format="yyyy-MM-dd"
                                format="yyyy-MM-dd"
                                v-model="releaseTime"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="发布起始日期"
                                end-placeholder="发布结束日期" style="width: 35%">
                </el-date-picker>
                <el-button type="primary" @click="queryData(1)" size="small" icon="el-icon-search">查询
                </el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-col>
            <el-col :span="4" class="text_right">
                <el-button type="primary" v-if="hasPermission('news:delete')"
                           :disabled="dataListSelections.length <= 0" @click="del()" size="small">删除
                </el-button>
                <el-button type="primary" v-if="hasPermission('news:add')"
                           @click="addEdit(null, 0)" size="small">
                    新增
                </el-button>
            </el-col>
        </el-row>
        <div class="bg-white">
            <div class="flex_b_c">
                <el-radio-group v-model="searchForm.reviewedState" size="small" @input="queryData(1)">
                    <el-radio-button label="3">全部</el-radio-button>
                    <el-radio-button label="0">待审核</el-radio-button>
                    <el-radio-button label="2">通过</el-radio-button>
                    <el-radio-button label="1">不通过</el-radio-button>
                </el-radio-group>
                <el-button type="primary" v-if="hasPermission('news:audit')"
                           :disabled="dataListSelections.length <= 0" @click="audit()" size="small">审核
                </el-button>
            </div>
            <el-table :data="list"
                      v-loading="loading"
                      @selection-change="selectionChangeHandle"
                      size="small"
                      height="calc(100vh - 310px)" class="table">
                <el-table-column type="selection" :selectable="checkSelectable" width="50" fixed></el-table-column>
                <el-table-column prop="journalismTitle" label="新闻标题" show-overflow-tooltip></el-table-column>
                <el-table-column prop="state" label="启用状态" show-overflow-tooltip v-if="hasPermission('news:enable')">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.state"
                                   @change="statusChange(scope.row)"
                                   :active-value="'0'"
                                   :inactive-value="'1'" :disabled="scope.row.reviewedState != 2"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="newsCategoryId" label="分类" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{getNewsCategoryName(scope.row.newsCategoryId)}}
                    </template>
                </el-table-column>
                <el-table-column prop="releaseTime" label="发布时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="views" label="浏览量" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createUser" label="添加人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="reviewedState" label="审核状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("new_auditStatus", scope.row.reviewedState ,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="reviewedUser" label="审核" show-overflow-tooltip></el-table-column>
                <el-table-column prop="reviewedTime" label="审核时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" label="添加时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="updateTime" label="最后修改时间" width="120" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" width="180" label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="hasPermission('news:view')" type="text" size="mini"
                                   @click="addEdit(scope.row, 2)">查看
                        </el-button>
                        <el-button v-if="hasPermission('news:edit')" :disabled="scope.row.state == '0'" type="text" size="mini"
                                   @click="addEdit(scope.row, 1)">编辑
                        </el-button>
                        <el-button v-if="hasPermission('news:audit') && scope.row.reviewedState == '0'" type="text"
                                   size="mini"
                                   @click="audit([scope.row.id])">审核
                        </el-button>
                        <el-button v-if="hasPermission('news:delete')" :disabled="scope.row.state == '0'" type="text"
                                   size="mini"
                                   @click="del([scope.row.id])">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="searchForm.current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <NewsForm ref="newsForm" @refreshDataList="queryData"></NewsForm>
        <el-dialog title="审核" width="40%" :close-on-click-modal="false" :visible.sync="auditVisible"
                   v-if="auditVisible">
            <el-form size="small" :model="auditForm" ref="auditForm" label-width="140px" class="p_r2">
                <el-form-item label="审核状态：" prop="reviewedState"
                              :rules="[{ required: true, message: '请选择审核状态', trigger: 'change' }]">
                    <el-radio v-model="auditForm.reviewedState" label="2">通过</el-radio>
                    <el-radio v-model="auditForm.reviewedState" label="1">驳回</el-radio>
                </el-form-item>
                <el-form-item label="备注：" prop="reviewedRemake">
                    <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入备注(限200字)"
                            v-model="auditForm.reviewedRemake" maxlength="200">
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="auditVisible = false">取消</el-button>
                <el-button size="small" type="primary" @click="submitAudit()" v-noMoreClick>提交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import NewsForm from './newsForm'

    export default {
        components: {NewsForm},
        data() {
            return {
                releaseTime: [],
                searchForm: {
                    journalismTitle: '',
                    newsCategoryId: '',
                    releaseStartTime: '',
                    releaseEndTime: '',
                    current: 1,
                    size: 10,
                    reviewedState: '3',
                },
                newsCategoryOpt: [],
                list: [],
                total: 0,
                loading: false,
                dataListSelections: [],
                // 审核
                auditVisible: false,
                auditForm: {
                    ids: [],
                    reviewedState: '',
                    reviewedRemake: '',
                },
            }
        },
        mounted() {
            this.queryData(1)
            this.getClassify()
        },
        methods: {
            // 获取数据
            queryData(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                let param = JSON.parse(JSON.stringify(this.searchForm))
                param.releaseStartTime = this.releaseTime ? this.releaseTime[0] : ''
                param.releaseEndTime = this.releaseTime ? this.releaseTime[1] : ''
                this.$axios(this.api.website.sysGetWebsiteJournalism, param, 'post').then((res) => {
                    if (res.status) {
                        this.list = res.data.records
                        if (this.list.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.queryData()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 获取分类
            getClassify() {
                this.$axios(this.api.website.allWebsiteNewsCategory).then((res) => {
                    if (res.status) {
                        this.newsCategoryOpt = res.data
                    } else {
                        this.$message.error('查询失败');
                    }
                })
            },
            // 过滤分类名称
            getNewsCategoryName(newsCategoryId) {
                let category = this.newsCategoryOpt.filter(item => item.id == newsCategoryId)
                if (category.length > 0) {
                    return category[0].newsCategoryName
                } else {
                    return ''
                }
            },
            checkSelectable(row) {
                return row.state == '1' && row.reviewedState != '2'
            },
            // 重置
            resetSearch() {
                this.releaseTime = []
                this.searchForm = {
                    journalismTitle: '',
                    newsCategoryId: '',
                    releaseStartTime: '',
                    releaseEndTime: '',
                    current: 1,
                    size: 10,
                    reviewedState: '3',
                }
                this.queryData(1)
            },
            // 状态修改
            statusChange(row) {
                this.$axios(this.api.website.websitejournalismUpdateEnableById, {
                    id: row.id,
                    state: row.state
                }, 'put').then((res) => {
                    if (res.status) {
                        this.$message.success('操作成功')
                        this.visible = false
                        this.queryData();
                    } else {
                        this.$message.error(res.msg)
                        this.queryData();
                    }
                })
            },
            // 新增、编辑、查看 row:数据；method：0新增，1编辑，2查看
            addEdit(row, method) {
                this.$refs.newsForm.init(row, method);
            },
            // 审核
            audit(id) {
                this.auditVisible = true
                this.auditForm = {
                    ids: [],
                    reviewedState: '',
                    reviewedRemake: '',
                }
                this.auditForm.ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
            },
            // 提交审核
            submitAudit() {
                this.$refs['auditForm'].validate((valid) => {
                    if (valid) {
                        this.$axios(this.api.website.reviewedWebsiteJournalism, this.auditForm, 'put').then((res) => {
                            if (res.status) {
                                this.$message.success('提交成功')
                                this.auditVisible = false
                                this.queryData();
                            } else {
                                this.$message.error(res.msg)
                            }
                        })
                    }
                })
            },
            // 删除
            del(id) {
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定删除这条数据吗?`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.$axios(this.api.website.websitejournalismBatchRemove, ids, 'post').then((res) => {
                        if (res.status) {
                            this.$message.success(res.msg)
                            this.queryData();
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                });
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.queryData()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.queryData()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
        }
    }
</script>

<style scoped>

</style>
